import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import BillingAddInputField from "../BillingAddInputField";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useMutation } from "react-query";
import axios from "axios";
import { useLocation } from "react-router-dom";
import PopUpModal from "../PopUpModal";
const Editroom = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const [updatePopup, setUpdatePopup] = useState(null);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [selectedFloor, setSelectedFloor] = useState("");
  const [roomCounts, setRoomCounts] = useState({});
  const [noOfFloors, setNoOfFloors] = useState(0);
  let hostelName = localStorage.getItem("hostelName");
  // console.log("HOSTEL NAME : ", hostelName);
  console.log("STATE", state);

  useEffect(() => {
    setValue("roomNumber", state?.roomNo);
    setValue("NoOfBeds", state?.noOfBeds);
    setValue("roomType", state?.roomType);
    setValue("ebNo", state?.ebNumber);
    setValue("foodOption", state?.foodOption);
  }, []);
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postdata) =>
      axios.put(
        `${process.env.REACT_APP_HOST}/rooms/updateNewRoom/${hostelName}/${
          state && state?.id
        }`,
        postdata
      ),
    {
      onSuccess: (val) => {
        console.log("data updated successfully...");
        setUpdatePopup(1);
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );

  useEffect(() => {
    if (state) {
      setValue("branchName", state?.branchName);
      setValue("noOfFloors", state?.noOfFloors);
      setValue("selectFloors", state?.selectFloor);
      setValue("noOfRooms", state?.noOfRooms);
      setValue("ebNumber", state?.ebNumber);
    }
  }, [state, setValue]);
  const onSubmit = (data) => {
    let formData;

    if (state && state.id != null) {
      formData = {
        roomNo: data?.roomNumber,
        noOfBeds: data?.NoOfBeds,
        ebNumber: data?.ebNumber,
        branchName: data?.branchName,
        noOfFloors: data?.noOfFloors,
        selectFloor: state?.selectFloors,
        noOfRooms: data?.noOfRooms,
        bedRoom: state?.bedRoom,
        kitchen: state?.kitchen,
        hall: state?.hall,
        roomType: "",
        selectHostel: "",
        foodOption: "",
      };
    }
    mutate(formData);
  };

  return (
    <>
      <div className="register-heading-block">
        <div className="new-room-heading">
          <img
            src="/assets/images/arrow-blue.png"
            alt="no-image"
            className="arrow-img-icon"
            onClick={() => {
              navigate(-1);
            }}
          />
          <h5 className="view-bill-heading --register-head pt-2">EDIT ROOM</h5>
        </div>
      </div>
      <div className="register-form-main-block">
        <div className="register-form-block my-3">
          <form onSubmit={handleSubmit(onSubmit)} className="py-4">
            <div className="row mx-4 p-0 mt-4 mb-2 align-items-center">
              <div className="col-12 col-sm-6 col-md-3 my-1">
                <label className="newRoomInputLabel">
                  BRANCH NAME{" "}
                  <span style={{ color: "red", fontSize: "16px" }}>*</span>
                </label>
              </div>
              <div className="col-12 col-sm-6 col-md-3 my-2">
                <Controller
                  name="branchName"
                  control={control}
                  defaultValue="JSK WOMENS PG"
                  rules={{ required: "This field is required" }}
                  render={({ field }) => (
                    <select
                      {...field}
                      className={`newRoomInputField ${
                        errors.branchName ? "error" : ""
                      }`}
                    >
                      <option value="">Branch Name</option>
                      <option value="JSK WOMENS PG">JSK WOMENS PG</option>
                    </select>
                  )}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-3 my-2">
                <label className="newRoomInputLabel">NO OF FLOORS</label>
              </div>
              <div className="col-12 col-sm-6 col-md-3 my-2">
                <Controller
                  name="noOfFloors"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      {...field}
                      className="newRoomInputField"
                      placeholder="No Of Floors"
                      type="number"
                      min="1"
                      disabled
                    />
                  )}
                />
              </div>
            </div>

            <div className="row mx-4 p-0 mt-4 mb-2 align-items-center">
              {/* <div className="col-12 col-sm-6 col-md-3 my-1">
                <label className="newRoomInputLabel">SELECT FLOOR</label>
              </div>
              <div className="col-12 col-sm-6 col-md-3 my-2">
                <Controller
                  name="selectFloors"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <select
                      className="newRoomInputField"
                      onChange={(e) => {
                        const floor = e.target.value;
                        onChange(floor);
                        setSelectedFloor(floor);
                      }}
                    >
                      <option value="">Select Floor</option>
                      {generateFloorOptions().map((floor) => (
                        <option key={floor} value={floor}>
                          {floor}
                        </option>
                      ))}
                      value={value} // Bind the value to the input onBlur=
                      {onBlur}
                      ref={ref}
                    </select>
                  )}
                />
              </div> */}
              <div className="col-12 col-sm-6 col-md-3 my-2">
                <label className="newRoomInputLabel">NO OF ROOMS</label>
              </div>
              <div className="col-12 col-sm-6 col-md-3 my-2">
                <Controller
                  name="noOfRooms"
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <input
                      {...field}
                      placeholder="No of Rooms"
                      className="newRoomInputField"
                      type="number"
                      min="0"
                      disabled
                    />
                  )}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-3 my-1">
                <label className="newRoomInputLabel">ROOM NUMBER</label>
              </div>
              <div className="col-12 col-sm-6 col-md-3 my-2">
                <Controller
                  control={control}
                  name="roomNumber"
                  render={(field) => (
                    <BillingAddInputField
                      {...field}
                      placeholder="Room Number"
                      type="text"
                      newRoomInputField="newRoomInputField"
                    />
                  )}
                />
              </div>
            </div>
            <div className="row mx-4 p-0 mt-4 mb-2 align-items-center">
              <div className="col-12 col-sm-6 col-md-3 my-2">
                <label className="newRoomInputLabel">
                  NO OF BEDS{" "}
                  <span style={{ color: "red", fontSize: "16px" }}>*</span>
                </label>
              </div>
              <div className="col-12 col-sm-6 col-md-3 my-2">
                <Controller
                  control={control}
                  name="NoOfBeds"
                  render={(field) => (
                    <BillingAddInputField
                      {...field}
                      placeholder="Sharing"
                      type="number"
                      newRoomInputField="newRoomInputField"
                    />
                  )}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-3 my-2">
                <label className="newRoomInputLabel">EB NUMBER</label>
              </div>
              <div className="col-12 col-sm-6 col-md-3 my-2">
                <Controller
                  control={control}
                  name="ebNumber"
                  render={(field) => (
                    <BillingAddInputField
                      {...field}
                      placeholder="EB Number"
                      type="text"
                      popupInputField="popup-input-field"
                    />
                  )}
                />
              </div>
            </div>
            <div className="row mx-4 p-0 mt-4 mb-2 align-items-center">
              <div className="col-12 d-flex justify-content-end">
                <button type="submit" className="new-room-submit-btn">
                  {/* SUBMIT */}
                  {isLoading ? <i class="fa fa-spinner fa-spin"></i> : "SUBMIT"}
                </button>
              </div>
              {/* <div className="col-12 col-sm-6 col-md-3 my-1">
                <label className="newRoomInputLabel">ROOM NUMBER</label>
              </div>
              <div className="col-12 col-sm-6 col-md-3 my-2">
                <Controller
                  control={control}
                  name="roomNumber"
                  render={(field) => (
                    <BillingAddInputField
                      {...field}
                      placeholder="Room Number"
                      type="text"
                      newRoomInputField="newRoomInputField"
                    />
                  )}
                />
              </div> */}
            </div>
          </form>
        </div>
      </div>
      <PopUpModal isOpen={updatePopup === null}>
        <div className="p-0 m-0 popupBoxBack">
          <div
            style={{ width: "25px", height: "25px", cursor: "pointer" }}
            onClick={() => {
              setUpdatePopup(null);
            }}
          >
            <img src="/assets/images/close.png" className="popUpBackGround" />
          </div>
        </div>
        <div className=" pt-2 d-flex flex-column justify-content-center align-items-center">
          <p className="popupDeleteUser  m-0 p-o">
            Room has been updated successfully..
          </p>
          <button
            onClick={() => {
              navigate("/");
            }}
          >
            OK
          </button>
        </div>
      </PopUpModal>
    </>
  );
};

export default Editroom;
