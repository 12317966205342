import axios from "axios";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import PopUpModal from "../PopUpModal";

const ViewCustomer = () => {
  const itemsPerPage = 8;
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [addSubTicketPopUp, setAddSubTicketPopUp] = useState(null);
  const [deletePopup, setDeletePopUp] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const navigate = useNavigate();

  let userLogin = localStorage.getItem("billInfo");
  let loginUser = Array(JSON.parse(userLogin));
  console.log("userLogin", loginUser);

  const deleteItem = async (id) => {
    await axios.delete(
      `${process.env.REACT_APP_HOST}/registers/${id}`
    );
  };

  const { isLoading, isError, mutate, refetch } = useMutation(deleteItem, {
    onSuccess: () => {
      refetch();
      setDeletePopUp(1); // Show success popup
    },
    onError: (err) => {
      console.log("Error", err);
    },
  });

  const handleDelete = (id) => {
    mutate(id);
    setAddSubTicketPopUp(null); // Close confirmation popup
  };

  useEffect(() => {
    // Reset to page 1 when search term changes
    setCurrentPage(1);
  }, [searchTerm]);
  return (
    <>
      <div className="view-register-head-block">
        <div className="d-flex align-items-center">
          <img
            src="/assets/images/back-arrow.png"
            className="arrow-img-icon"
            alt="no-image"
            onClick={() => navigate(-1)}
          />
          <h5 className="view-bill-heading pt-2">VIEW CUSTOMER REGISTER</h5>
        </div>
      </div>
      <div className="view-bill-main-block">
        <div className="table-responsive">
          <table className="table table-container">
            <thead>
              <tr>
                <th className="view-bill-head --first-head">CUSTOMER NAME</th>
                <th className="view-bill-head">EMAIL ID</th>
                <th className="view-bill-head">MOBILE NUMBER</th>
                <th className="view-bill-head">SHOP NAME</th>
                <th className="view-bill-head">ADDRESS</th>
                <th className="view-bill-head">BANK NAME</th>
                <th className="view-bill-head">ACCOUNT NUMBER</th>
                <th className="view-bill-head">ACCOUNT TYPE</th>
                <th className="view-bill-head">IFSC</th>
                <th className="view-bill-head">BRANCH</th>
                <th className="view-bill-head">REGISTER NUMBER</th>
                <th className="view-bill-head --last-head">EDIT</th>
              </tr>
            </thead>
            <tbody>
              {loginUser && loginUser != null ? (
                loginUser?.map((item, index) => (
                  <tr key={index}>
                    <td className="view-bill-data">{item?.customerName}</td>
                    <td className="view-bill-data">{item?.emailId}</td>
                    <td className="view-bill-data">{item?.customerPhno}</td>
                    <td className="view-bill-data">{item?.shopName}</td>
                    <td className="view-bill-data">{item?.companyAddress}</td>
                    <td className="view-bill-data">{item?.bankName}</td>
                    <td className="view-bill-data">{item?.accountNumber}</td>
                    <td className="view-bill-data">{item?.accountType}</td>
                    <td className="view-bill-data">{item?.ifscCode}</td>
                    <td className="view-bill-data">{item?.branch}</td>
                    <td className="view-bill-data">{item?.registerNumber}</td>
                    <td className="view-bill-data">
                      <img
                        src="/assets/images/edit-icon.png"
                        className="--image-click"
                        alt="no-image"
                        onClick={() =>
                          navigate("/loginRegister", { state: item })
                        }
                      />
                      <img
                        src="/assets/images/delete-icon.png"
                        className="--image-click"
                        alt="no-image"
                        onClick={() => {
                          setAddSubTicketPopUp(true);

                          setDeleteId(item?.id);
                          localStorage.removeItem("login");
                          localStorage.removeItem("hostelName");
                          localStorage.removeItem("billInfo");
                          navigate("/");
                        }}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="11">No results found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <PopUpModal isOpen={addSubTicketPopUp === null}>
        <div className="p-0 m-0 popupBoxBack">
          <div
            style={{ width: "15px", height: "15px", cursor: "pointer" }}
            onClick={() => setAddSubTicketPopUp(null)}
          >
            <img src="/assets/images/close.png" className="popUpBackGround" />
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <p className="popupDeleteUser m-0 px-1">
            Are you sure you want to delete the customer detail?
          </p>
          <div className="d-flex justify-content-center align-items-center gap-2">
            <button
              onClick={() => {
                handleDelete(deleteId);
                setDeletePopUp(1);
              }}
            >
              {isLoading ? <i class="fa fa-spinner fa-spin"></i> : "YES"}
            </button>
            <button onClick={() => setAddSubTicketPopUp(null)}>NO</button>
          </div>
        </div>
      </PopUpModal>

      <PopUpModal isOpen={deletePopup === null}>
        <div className="p-0 m-0 popupBoxBack">
          <div
            style={{ width: "15px", height: "15px", cursor: "pointer" }}
            onClick={() => setDeletePopUp(null)}
          >
            <img src="/assets/images/close.png" className="popUpBackGround" />
          </div>
        </div>
        <div className="pt-2 d-flex flex-column justify-content-center align-items-center">
          <p className="popupDeleteUser m-0 p-0">
            Customer has been deleted successfully.
          </p>
          <button
            onClick={() => {
              setDeletePopUp(null);
            }}
          >
            OK
          </button>
        </div>
      </PopUpModal>
    </>
  );
};

export default ViewCustomer;
