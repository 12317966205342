import axios from "axios";
import React, { useState, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import PopUpModal from "../components/PopUpModal";
import Sidebar from "../components/Sidebar";
import html2pdf from "html2pdf.js";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
const ViewBill = () => {
  const queryClient = useQueryClient();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [addSubTicketPopUp, setAddSubTicketPopUp] = useState(null);
  const [deletePopup, setDeletePopUp] = useState(null);
  const itemsPerPage = 5;
  const [deleteId, setDeleteId] = useState(null);
  const navigate = useNavigate();
  const [filter, setFilter] = useState("");
  const [count, setCount] = useState(0);
  const [paid, setPaid] = useState(0);
  const [pendingamt, setPendingamt] = useState(0);
  let hostelName = localStorage.getItem("hostelName");
  console.log("HOSTEL NAME : ", hostelName);
  const getViewBill = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_HOST}/newbills/getAllNewBill/${hostelName}`
    );
    return response.data;
  };

  const { data, isLoading, isError, refetch } = useQuery(
    "getViewBill",
    getViewBill
  );

  const details = data;
  // console.log("details", details);

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const applyFilterAndSearch = () => {
      if (!details) {
        setFilteredData([]);
        return;
      }

      // Normalize searchTerm by converting to lowercase and removing spaces
      const normalizedSearchTerm =
        searchTerm?.toLowerCase().replace(/\s+/g, "") || "";

      const result = details.filter((item) => {
        // Ensure that the filter criteria is applied
        const matchesFilter = filter ? item.feesStatus === filter : true;

        // Normalize item fields to lowercase and remove spaces for comparison
        const itemRoomNo = (item?.roomNo ?? "")
          .toString()
          .toLowerCase()
          .replace(/\s+/g, "");
        const itemName = (item?.name ?? "")
          .toString()
          .toLowerCase()
          .replace(/\s+/g, "");
        const itemBillNo = (item?.billNo ?? "")
          .toString()
          .toLowerCase()
          .replace(/\s+/g, "");

        // Check if any of the normalized fields match the normalized searchTerm
        const matchesSearch =
          !searchTerm ||
          itemRoomNo.startsWith(normalizedSearchTerm) ||
          itemName.startsWith(normalizedSearchTerm) ||
          itemBillNo.startsWith(normalizedSearchTerm);

        return matchesFilter && matchesSearch;
      });

      setFilteredData(result);
    };

    applyFilterAndSearch();
  }, [details, filter, searchTerm]);

  // Pagination logic
  const totalPages = Math.ceil(filteredData?.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);

  // Pagination handlers
  const isPrevDisabled = currentPage === 1;
  const isNextDisabled = currentPage === totalPages || totalPages === 0;

  const handlePrevPage = () => {
    if (!isPrevDisabled) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (!isNextDisabled) setCurrentPage(currentPage + 1);
  };

  const deleteItem = async (id) => {
    await axios.delete(
      `${process.env.REACT_APP_HOST}/newbills/${hostelName}/${id}`
    );
  };

  const mutation = useMutation(deleteItem, {
    onSuccess: () => {
      refetch();
      setDeletePopUp(1); // Show success popup
    },
    onError: (err) => {
      console.log("Error", err);
    },
  });

  const handleDelete = (id) => {
    mutation.mutate(id);
    setAddSubTicketPopUp(null); // Close confirmation popup
  };
  useEffect(() => {
    // Initialize totals
    let totalbill = 0;
    let paidCount = 0;
    let pendingCount = 0;

    // Compute totals
    filteredData?.forEach((item) => {
      totalbill += 1;
      if (item?.remainingAmount === 0) {
        paidCount += 1;
      } else if (item?.remainingAmount !== 0) {
        pendingCount += 1;
      }
    });

    // Update state with computed totals
    setCount(totalbill);
    setPaid(paidCount);
    setPendingamt(pendingCount);
  }, [currentItems]);

  // useEffect(() => {
  //   const datas = details?.map((val, i) => {
  //     return val?.date;
  //   });

  //   setCurrentPage(1);
  // }, [searchTerm]);

  // Function to handle Excel download
  const downloadExcel = () => {
    // Determine data to export based on filter
    const exportData = filteredData?.length > 0 ? filteredData : data;

    // Prepare data for the table worksheet
    const tableDatas = exportData?.map((data, index) => ({
      "S.NO": index + 1,
      NAME: data?.name || "NULL",
      "ROOM NO": data?.roomNo || "NULL",
      "MOBILE NUMBER": data?.mobileNumber || "NULL",
      "EMAIL ID": data?.emailId || "NULL",
      "DATE OF BILL": data?.date || "NULL",
      "BILL NO": data?.billNo || "NULL",
      "ADVANCE PAYMENT": data?.advanceAmount || "NULL",
      "RENT FOR MONTH": data?.rentForMonth || "NULL",
      "EB AMOUNT": data?.currentAmount || "NULL",
      OTHERS: data?.others || "NULL",
      "TOTAL AMOUNT": data?.total || "NULL",
      "TOTAL PAID": data?.currentlypaidAmount || "NULL",
      "PENDING AMOUNT": data?.remainingAmount || "NULL",
    }));

    // Create table worksheet with headers
    const tableWorksheet = XLSX.utils.json_to_sheet(tableDatas, {
      header: [
        "S.NO",
        "NAME",
        "ROOM NO",
        "MOBILE NUMBER",
        "EMAIL ID",
        "DATE OF BILL",
        "BILL NO",

        "ADVANCE PAYMENT",
        "RENT FOR MONTH",
        "EB AMOUNT",
        "OTHERS",
        "TOTAL AMOUNT",
        "TOTAL PAID",
        "PENDING AMOUNT",
      ],
      skipHeader: false, // Ensure the header is included
    });

    // Create summary data
    const summaryData = [
      {
        Label: "NAME",
        Value: exportData?.map((data) => data?.name).join(", ") || "NULL",
      },
      {
        Label: "ROOM NO",
        Value: exportData.map((data) => data?.roomNo).join(", ") || "NULL",
      },
      {
        Label: "MOBILE NUMBER",
        Value:
          exportData.map((data) => data?.mobileNumber).join(", ") || "NULL",
      },
      {
        Label: "EMAIL ID",
        Value: exportData.map((data) => data?.emailId).join(", ") || "NULL",
      },
      {
        Label: "DATE OF BILL",
        Value: exportData.map((data) => data?.date).join(", ") || "NULL",
      },
      {
        Label: "ADVANCE PAYMENT",
        Value:
          exportData.map((data) => data?.advanceAmount).join(", ") || "NULL",
      },
      {
        Label: "RENT FOR MONTH",
        Value:
          exportData.map((data) => data?.rentForMonth).join(", ") || "NULL",
      },

      {
        Label: "EB AMOUNT",
        Value:
          exportData.map((data) => data?.currentAmount).join(", ") || "NULL",
      },
      {
        Label: "OTHERS",
        Value: exportData.map((data) => data?.others).join(", ") || "NULL",
      },
      {
        Label: "TOTAL AMOUNT",
        Value: exportData.map((data) => data?.total).join(", ") || "NULL",
      },
      {
        Label: "TOTAL PAID",
        Value:
          exportData.map((data) => data?.currentlypaidAmount).join(", ") ||
          "NULL",
      },
      {
        Label: "PENDING AMOUNT",
        Value:
          exportData.map((data) => data?.remainingAmount).join(", ") || "NULL",
      },
    ];

    // Create summary worksheet with headers
    const summaryWorksheet = XLSX.utils.json_to_sheet(summaryData, {
      header: ["Label", "Value"],
      skipHeader: false,
    });

    // Create a new workbook and add the worksheets
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, tableWorksheet, "Table Data");
    XLSX.utils.book_append_sheet(workbook, summaryWorksheet, "Summary Data");

    // Generate the Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Save the Excel file
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "View Bill.xlsx");
  };

  return (
    <>
      <section className="viewBillMainSection">
        <div className="container-fluid">
          <div className="row p-0">
            <div className="col-lg-2 p-0 m-0">
              <Sidebar />
            </div>
            <div className="col-lg-10 p-0 m-0">
              <div className="view-bill-head-block">
                <div className="view-bill-title-block">
                  <img
                    src="/assets/images/arrow-blue.png"
                    style={{ width: "40px", height: "40px", cursor: "pointer" }}
                    className="arrow-img-icon"
                    alt="no-image"
                    onClick={() => navigate(-1)}
                  />
                  <h5 className="register-title pt-2">VIEW BILL</h5>
                </div>
                <div className="view-bill-search-container">
                  <div
                    className="overview-download-block"
                    onClick={() => downloadExcel()}
                  >
                    <div className="search-icon">
                      <img
                        src="/assets/images/download.png"
                        alt="no-image"
                        className="crm-image"
                      />
                    </div>
                    <button className="overview-btn">DOWNLOAD</button>
                  </div>

                  <select
                    className="view-bill-status"
                    onChange={(e) => setFilter(e.target.value)}
                    value={filter}
                  >
                    <option value="">Filter</option>
                    <option value="Paid">Paid</option>
                    <option value="Pending">Pending</option>
                  </select>
                  <input
                    type="search"
                    className="view-bill-search"
                    placeholder="search by roomno , name , billno"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>

              <div className="status-bar-block">
                <div className="status-row-bar">
                  <div className="bill-status-bar --first-bar">
                    <p className="bill-status-content">PAID</p>
                    <p className="bill-status-count">{paid} Person</p>
                  </div>
                  <div className="bill-status-bar">
                    <p className="bill-status-content">TOTAL</p>
                    <p className="bill-status-count">{count} Person</p>
                  </div>
                  <div className="bill-status-bar --last-bar">
                    <p className="bill-status-content">PENDING</p>
                    <p className="bill-status-count">{pendingamt} Person</p>
                  </div>{" "}
                </div>
              </div>

              <div className="view-register-table-block">
                <div className="view-bill-main-block">
                  <div className="table-responsive table-container">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="view-bill-head --first-head">NAME</th>
                          <th className="view-bill-head">DOWNLOAD</th>
                          <th className="view-bill-head">ROOM NO</th>
                          <th className="view-bill-head">BILL NO</th>
                          <th className="view-bill-head">MOBILE NUMBER</th>
                          <th className="view-bill-head">EMAILID</th>
                          <th className="view-bill-head">DATE</th>
                          <th className="view-bill-head">RENT FOR MONTH</th>
                          <th className="view-bill-head">EB AMOUNT</th>
                          <th className="view-bill-head">OTHERS</th>
                          <th className="view-bill-head">TOTAL AMOUNT</th>
                          {/* <th className="view-bill-head">AMOUNT PAID</th>
                          <th className="view-bill-head">PENDING AMOUNT</th> */}
                          <th className="view-bill-head --last-head">EDIT</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems && currentItems.length > 0 ? (
                          currentItems?.map((item, index) => {
                            return (
                              <>
                                <tr key={index}>
                                  <td className="view-bill-data">
                                    {item?.name}
                                  </td>
                                  <td className="view-bill-data">
                                    <img
                                      src="/assets/images/download_icon.png"
                                      alt="no-image"
                                      className="--image-click"
                                      onClick={() => {
                                        navigate("/billingInvoice", {
                                          state: item,
                                        });
                                      }}
                                    />
                                  </td>
                                  <td className="view-bill-data">
                                    {item?.roomNo}
                                  </td>
                                  <td className="view-bill-data">
                                    {item?.billNo}
                                  </td>
                                  <td className="view-bill-data">
                                    {item?.mobileNumber}
                                  </td>
                                  <td className="view-bill-data">
                                    {item?.emailId}
                                  </td>
                                  <td className="view-bill-data">
                                    {item?.date}
                                  </td>
                                  <td className="view-bill-data">
                                    {item?.rentForMonth}
                                  </td>
                                  <td className="view-bill-data">
                                    {item?.currentAmount}
                                  </td>
                                  <td className="view-bill-data">
                                    {item?.others}
                                  </td>
                                  <td className="view-bill-data">
                                    {item?.total}
                                  </td>
                                  {/* <td className="view-bill-data">
                                    {item?.currentlypaidAmount}
                                  </td>
                                  <td className="view-bill-data">
                                    {item?.remainingAmount}
                                  </td> */}
                                  <td className="view-bill-data">
                                    <img
                                      src="/assets/images/edit-icon.png"
                                      className="--image-click"
                                      alt="no-image"
                                      onClick={() =>
                                        navigate("/editBill", { state: item })
                                      }
                                    />
                                    <img
                                      src="/assets/images/delete-icon.png"
                                      className="--image-click"
                                      alt="no-image"
                                      onClick={() => {
                                        setAddSubTicketPopUp(true);
                                        setDeleteId(item?.id);
                                      }}
                                    />
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="14">No results found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div className="d-flex justify-content-between pt-2 px-1">
                    <button
                      onClick={handlePrevPage}
                      disabled={isPrevDisabled}
                      className="arrow-btn"
                    >
                      &lt;
                    </button>
                    <button
                      onClick={handleNextPage}
                      disabled={isNextDisabled}
                      className="arrow-btn"
                    >
                      &gt;
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <PopUpModal isOpen={addSubTicketPopUp === null}>
        <div className="popup-overlay">
          <div className="confirmation-popup">
            <div className="p-0 m-0 popupBoxBack">
              <div
                style={{ width: "25px", height: "25px", cursor: "pointer" }}
                onClick={() => setAddSubTicketPopUp(null)}
              >
                <img
                  src="/assets/images/close.png"
                  className="popUpBackGround"
                />
              </div>
            </div>
            <div className="pt-2 d-flex flex-column justify-content-center align-items-center">
              <p className="popupDeleteUser m-0 px-1">
                Are you sure you want to delete the user detail?
              </p>
              <div className="d-flex justify-content-center align-items-center gap-2">
                <button
                  onClick={() => {
                    handleDelete(deleteId);
                  }}
                >
                  {isLoading ? <i class="fa fa-spinner fa-spin"></i> : "YES"}
                </button>
                <button onClick={() => setAddSubTicketPopUp(null)}>NO</button>
              </div>
            </div>
          </div>
        </div>
      </PopUpModal>

      <PopUpModal isOpen={deletePopup === null}>
        <div className="popup-overlay">
          <div className="confirmation-popup">
            <div className="p-0 m-0 popupBoxBack">
              <div
                style={{ width: "25px", height: "25px", cursor: "pointer" }}
                onClick={() => setDeletePopUp(null)}
              >
                <img
                  src="/assets/images/close.png"
                  className="popUpBackGround"
                />
              </div>
            </div>
            <div className="pt-2 d-flex flex-column justify-content-center align-items-center">
              <p className="popupDeleteUser m-0 p-0">
                Bill has been deleted successfully.
              </p>
              <button
                onClick={() => {
                  setDeletePopUp(null);
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </PopUpModal>
    </>
  );
};

export default ViewBill;
