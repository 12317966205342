import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import BillingAddInputField from "../components/BillingAddInputField";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import axios from "axios";
import PopUpModal from "../components/PopUpModal";
import Sidebar from "../components/Sidebar";
const EditRegister = () => {
  const [updatePopup, setUpdatePopup] = useState(null);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const navigate = useNavigate();
  const { state } = useLocation();
  let hostelName = localStorage.getItem("hostelName");
  // console.log("HOSTEL NAME : ", hostelName);
  console.log("state", state);
  useEffect(() => {
    setValue("name", state?.name);
    setValue("mobileNo", state?.mobileNo);
    setValue("emergencyNo", state?.emergencyNo);
    setValue("emailId", state?.emailId);
    setValue("dateOfBirth", state?.dateOfBirth);
    setValue("dateOfJoining", state?.dateOfJoining);
    setValue("roomNo", state?.roomNo);
    setValue("address", state?.address);
    setValue("billNo", state?.billNo);
    setValue("bedNo", state?.bedNo);
    setValue("selectFoodOption", state?.selectFoodOption);
    setValue("advancePayment", state?.advanceAmount);
    setValue("rentForMonth", state?.rentForMonth);
    setValue("status", state?.status);
    setValue("professionType", state?.proffesionType);
    setValue("aadharNumber", state?.aadharNumber);
    setValue("parentialOrGuardian", state?.parentialOrGuardian);
    setValue("familyNumber", state?.familyNumber);
  }, []);

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postdata) =>
      axios.put(
        `${
          process.env.REACT_APP_HOST
        }/newregister/updateNewRegister/${hostelName}/${state && state.id}`,
        postdata
      ),
    {
      onSuccess: (val) => {
        console.log("data updated successfully...");
        setUpdatePopup(1);
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );
  // Function to get today's date in YYYY-MM-DD format
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const onSubmit = (data) => {
    let formData;
    if (state && state.id != null) {
      formData = {
        name: data?.name,
        mobileNo: data?.mobileNo,
        emailId: data?.emailId,
        roomNo: data?.roomNo,
        emergencyNo: data?.emergencyNo,
        dateOfBirth: moment(data?.dateOfBirth).format("YYYY-MM-DD"),
        dateOfJoining: moment(data?.dateOfJoining).format("YYYY-MM-DD"),
        address: data?.address,
        selectHostel: data?.dateOfLeaving,
        bedNo: data?.bedNo,
        selectFoodOption: data?.selectFoodOption,
        advanceAmount: data?.advancePayment,
        rentForMonth: data?.rentForMonth,
        status: data?.status,
        proffesionType: data?.professionType,
        aadharNumber: data?.aadharNumber,
        parentialOrGuardian: data?.parentialOrGuardian,
        familyNumber: data?.familyNumber,
      };
    }
    mutate(formData);
  };
  const { status } = watch();
  return (
    <>
      <section className="registrationMainContainer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2 p-0 m-0">
              <Sidebar />
            </div>
            <div className="col-lg-10 p-0 m-0">
              <div className="register-heading-block">
                <div className="d-flex align-items-center">
                  <img
                    src="/assets/images/arrow-blue.png"
                    style={{ width: "40px", height: "40px", cursor: "pointer" }}
                    alt="no-image"
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                  <h5 className="view-bill-heading --register-head pt-2">
                    EDIT REGISTER
                  </h5>
                </div>
              </div>
              <div className="register-form-main-block my-3">
                <div className="register-form-block">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="new-register-input-block">
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          NAME <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="name"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Name"
                                type="text"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </div>
                      </div>
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          EMAIL ID
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="emailId"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Email Id"
                                type="emailid"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            // rules={{ required: true }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="new-register-input-block">
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          MOBILE NUMBER <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="mobileNo"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Mobile Number"
                                type="tele"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </div>
                      </div>
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          ALTERNATIVE NUMBER
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="emergencyNo"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Emergency Number"
                                type="tele"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            // rules={{ required: true }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="new-register-input-block">
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          PROFESSION TYPE
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="professionType"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Profession Type"
                                type="text"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            // rules={{ required: true }}
                          />
                        </div>
                      </div>
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          AADHAR NUMBER
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="aadharNumber"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Aadhar Number"
                                type="number"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            // rules={{ required: true }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="new-register-input-block">
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          PARENT/GUARDIAN
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            name="parentialOrGuardian"
                            control={control}
                            defaultValue="Father/Mother" // Set the default value here
                            render={({ field }) => (
                              <select {...field} className="bookingEditInput">
                                <option value="Father/Mother">
                                  Father/Mother
                                </option>
                                <option value="Local Guardian">
                                  Local Guardian
                                </option>
                              </select>
                            )}
                          />
                        </div>
                      </div>
                      {watch("parentialOrGuardian") === "Father/Mother" ? ( // Corrected this line
                        <>
                          <div className="register-container">
                            <label className="BillingAddInputLabel --new-register-label-two">
                              FATHER/MOTHER NUMBER
                            </label>
                            <div className="newRegisterInputBlockTwo">
                              <Controller
                                control={control}
                                name="familyNumber"
                                render={(field) => (
                                  <BillingAddInputField
                                    {...field}
                                    placeholder="Father/Mother Number"
                                    type="tel" // Changed to "tel" for telephone input
                                    newRegisterInput="newRegisterInput"
                                  />
                                )}
                                // rules={{ required: true }} // Uncomment if you want to enforce this field as required
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="register-container">
                            <label className="BillingAddInputLabel --new-register-label-two">
                              LOCAL GUARDIAN NUMBER
                            </label>
                            <div className="newRegisterInputBlockTwo">
                              <Controller
                                control={control}
                                name="familyNumber"
                                render={(field) => (
                                  <BillingAddInputField
                                    {...field}
                                    placeholder="Guardian Number"
                                    type="tel" // Changed to "tel" for telephone input
                                    newRegisterInput="newRegisterInput"
                                  />
                                )}
                                // rules={{ required: true }} // Uncomment if you want to enforce this field as required
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="new-register-input-block">
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          DATE OF BIRTH
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            name="dateOfBirth"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <DatePicker
                                className="newRegisterInput"
                                selected={
                                  field.value ? new Date(field.value) : null
                                }
                                onChange={(date) => {
                                  // Format the selected date and update the field value
                                  field.onChange(
                                    date
                                      ? moment(date).format("YYYY-MM-DD")
                                      : ""
                                  );
                                }}
                                placeholderText="DD/MM/YYYY"
                                dateFormat="dd/MM/yyyy" // Format displayed in the date picker
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          DATE OF JOINING{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="dateOfJoining"
                            render={({ field }) => (
                              <DatePicker
                                // className="newRegisterInput"
                                className={`newRegisterInput ${
                                  errors.dateOfJoining ? "error" : ""
                                }`} // Conditional class for error
                                onChange={(date) => field.onChange(date)}
                                selected={field.value}
                                placeholderText="DD/MM/YY"
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="new-register-input-block">
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          ROOM NO <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="roomNo"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Room No"
                                type="text"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </div>
                      </div>
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          BED NUMBER
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="bedNo"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Bed Number"
                                type="text"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            // rules={{ required: true }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="new-register-input-block">
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          SELECT FOOD OPTION{" "}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            name="selectFoodOption"
                            control={control}
                            defaultValue=""
                            // rules={{ required: "Food option is required" }}
                            render={({ field }) => (
                              <select
                                {...field}
                                className={`newRegisterInput ${
                                  errors.selectFoodOption ? "error" : ""
                                }`}
                              >
                                {" "}
                                {/* Conditional class for error */}
                                <option value="">Select Food</option>
                                <option value="With Food">With Food</option>
                                <option value="Without Food">
                                  Without Food
                                </option>
                                <option value="Own Cooking">Own Cooking</option>
                              </select>
                            )}
                          />
                        </div>
                      </div>
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          ADVANCE PAYMENT{" "}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="advancePayment"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Advance Payment"
                                type="number"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            // rules={{ required: true }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="new-register-input-block">
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          RENT FOR MONTH <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="rentForMonth"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Rent For Month"
                                type="number"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </div>
                      </div>
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          ADDRESS
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="address"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Address"
                                type="text"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            // rules={{ required: true }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="new-register-input-block">
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          STATUS
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            name="status"
                            control={control}
                            defaultValue=""
                            // rules={{ required: "Status is required" }}
                            render={({ field }) => (
                              <select
                                {...field}
                                className={`newRegisterInput ${
                                  errors.status ? "error" : ""
                                }`}
                              >
                                <option value="">Status</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                              </select>
                            )}
                          />
                        </div>
                      </div>
                      {status == "Inactive" ? (
                        <>
                          <div className="register-container">
                            <label className="BillingAddInputLabel --new-register-label-two">
                              DATE OF LEAVING{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="newRegisterInputBlockTwo">
                              <Controller
                                control={control}
                                name="dateOfLeaving"
                                render={({ field }) => (
                                  <DatePicker
                                    className="newRegisterInput"
                                    onChange={(date) => field.onChange(date)}
                                    selected={field.value}
                                    // dateFormat="YYYY/MM/DD"
                                    // dateFormat='Pp/'
                                    placeholderText="DD/MM/YY"
                                    minDate={new Date(getTodayDate())} // Disable previous days
                                  />
                                )}
                                rules={{ required: true }}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="new-register-input-block mt-4">
                      <div className="register-btn-block ">
                        <button className="register-submit-btn">
                          {isLoading ? (
                            <i class="fa fa-spinner fa-spin"></i>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PopUpModal isOpen={updatePopup === null}>
        <div className="popup-overlay">
          <div className="confirmation-popup">
            <div className="p-0 m-0 popupBoxBack">
              <div
                style={{ width: "25px", height: "25px", cursor: "pointer" }}
                onClick={() => {
                  setUpdatePopup(null);
                }}
              >
                <img
                  src="/assets/images/close.png"
                  className="popUpBackGround"
                />
              </div>
            </div>
            <div className=" pt-2 d-flex flex-column justify-content-center align-items-center">
              <p className="popupDeleteUser  m-0 p-o">
                User has been updated successfully..
              </p>
              <button
                onClick={() => {
                  navigate("/viewRegister");
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </PopUpModal>
    </>
  );
};

export default EditRegister;
