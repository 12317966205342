import axios from "axios";
import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import PopUpModal from "../PopUpModal";
import { getViewRoom } from "../../globalAPI/Api";
const ViewRoomForm = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  let hostelName = localStorage.getItem("hostelName");
  const { data, isLoading, isError } = useQuery("getViewRoom", getViewRoom);
  let details = data && data?.data;
  console.log("DETAILS", details);
  useEffect(() => {
    const applyFilterAndSearch = () => {
      if (!Array.isArray(details)) {
        setFilteredData([]); // Ensure filteredData is cleared if data is not an array
        return;
      }

      const normalizedSearchTerm =
        searchTerm?.toLowerCase().replace(/\s+/g, "") || ""; // Normalize search term by removing spaces
      const result = details.filter((item) => {
        const matchesFilter = filter
          ? item?.status.toLowerCase() === filter.toLowerCase()
          : true;

        // Normalize itemRoomNo by removing spaces
        const itemRoomNo = (item?.roomNo ?? "")
          .toString()
          .toLowerCase()
          .replace(/\s+/g, "");

        // Check if normalized itemRoomNo starts with normalized searchTerm
        const matchesSearch = normalizedSearchTerm
          ? itemRoomNo.startsWith(normalizedSearchTerm)
          : true;

        return matchesFilter && matchesSearch;
      });

      setFilteredData(result);
    };

    applyFilterAndSearch();
  }, [details, filter, searchTerm]);

  return (
    <>
      <div className="view-room-head-block">
        <div className="view-room-heading">
          <img
            src="/assets/images/arrow-blue.png"
            style={{ width: "40px", height: "40px", cursor: "pointer" }}
            className="arrow-img-icon"
            alt="no-image"
            onClick={() => navigate(-1)}
          />
          <h5 className="register-title pt-2">VIEW ROOM</h5>
        </div>
        <div className="view-room-head-new-btn-block">
          <select
            className="view-hostel-status"
            onChange={(e) => setFilter(e.target.value)}
            value={filter}
          >
            <option value="">Hostel Status</option>
            <option value="Available">Available</option>
            <option value="Unavailable">Unavailable</option>
          </select>
          <button
            className="new-room-btn"
            onClick={() => {
              navigate("/newRoom");
            }}
          >
            NEW ROOM
          </button>
          <input
            type="search"
            className="table-search --view-room-search"
            placeholder="search by roomno"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
      <div className="view-room-block">
        <div className="view-room-main-block">
          {filteredData && filteredData?.length > 0 ? (
            filteredData?.map((d, i) => {
              return (
                <>
                  <div class="card  card-block" key={i}>
                    <div className="text-end">
                      <img
                        src="/assets/images/edit-icon.png"
                        className="--image-click"
                        alt="no-image"
                        onClick={() => {
                          navigate("/editRoom", { state: d });
                        }}
                      />
                    </div>
                    <div
                      class="card-body card-content-block"
                      onClick={() => {
                        navigate("/viewBeds", { state: d });
                      }}
                    >
                      <p className="card-content-para d-flex gap-3 align-items-center flex-wrap pb-1">
                        <span class="card-title">Room No : {d?.roomNo}</span>
                        <span className="card-title">
                          Sharing : {d?.noOfBeds}
                        </span>
                      </p>

                      <p className="card-content-ac-type  d-flex gap-3 align-items-center pb-1">
                        <span class="card-title"> BedRoom : {d?.bedRoom}</span>
                        <span className="card-title">
                          Kitchen : {d?.kitchen}
                        </span>
                      </p>
                      <p className="card-content-para pb-2">Hall : {d?.hall}</p>
                      {d?.status == "Available" || d?.status == "available" ? (
                        <p
                          className="hostel-status"
                          style={{ color: "greenyellow" }}
                        >
                          {d?.status}
                        </p>
                      ) : (
                        <p
                          className="hostel-status"
                          style={{ color: "yellow" }}
                        >
                          {d?.status}
                        </p>
                      )}
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <>
              <p className="no-result">NO ROOM AVAILABLE !!!</p>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewRoomForm;
