import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import PopupBox from "../popup/PopupBox";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useQuery } from "react-query";
import { getNoOfFloors } from "../../globalAPI/Api";

const NewRoomForm = () => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { roomType: [] },
  });

  const navigate = useNavigate();
  const { state } = useLocation();
  const [selectedFloor, setSelectedFloor] = useState("");
  const [roomCounts, setRoomCounts] = useState({});
  const [noOfFloors, setNoOfFloors] = useState(0);
  const [popupState, setPopupState] = useState({
    isOpen: false,
    roomData: null,
    roomNo: {},
  });
  let hostelName = localStorage.getItem("hostelName");

  const { data, isLoading, isError, refetch } = useQuery(
    "getNoOfFloors",
    getNoOfFloors
  );

  let nooffloor = data && data?.data;
  // console.log("data----", nooffloor);
  const numberofrooms = watch("selectFloors");
  // console.log("flooe", numberofrooms);
  const getNoOfRooms = async () => {
    let reponse = await axios.get(
      `${process.env.REACT_APP_HOST}/rooms/lastroomnobyfloor/${hostelName}/${numberofrooms}`
    );
    return reponse.data;
  };

  const { data: fetchnoofrooms } = useQuery(
    ["getNoOfRooms", hostelName, numberofrooms],
    getNoOfRooms,
    {
      enabled: !!numberofrooms, // Only fetch when selectFloors is available
    }
  );

  const generateFloorOptions = () => {
    return Array.from({ length: noOfFloors }, (_, i) => `Floor ${i + 1}`);
  };

  const handleRoomCountChange = (floor, count) => {
    setRoomCounts((prev) => ({ ...prev, [floor]: count }));
  };
  const branchName = watch("branchName");
  const noOfFloor = watch("noOfFloors");
  const selectFloors = watch("selectFloors");
  const noOfRooms = watch("noOfRooms");

  const handleOpenPopup = (roomNumber) => {
    setPopupState({
      isOpen: true,
      roomData: {
        branchName,
        noOfFloor,
        selectFloors,
        noOfRooms,
      },
      roomNo: { roomNumber },
    });
  };

  const handleClosePopup = () => {
    setPopupState({ isOpen: false, roomData: null, roomNo: {} });
  };
  useEffect(() => {
    setValue("noOfFloors", nooffloor);
    setNoOfFloors(nooffloor);
    setValue("noOfRooms", fetchnoofrooms);
  }, [nooffloor, setValue, fetchnoofrooms]);

  return (
    <>
      <div className="register-heading-block">
        <div className="new-room-heading">
          <img
            src="/assets/images/arrow-blue.png"
            alt="back"
            className="arrow-img-icon"
            onClick={() => navigate(-1)}
          />
          <h5 className="register-title pt-2">NEW ROOM</h5>
        </div>
        <div className="new-room-head">
          <button className="view-room-btn" onClick={() => navigate("/")}>
            VIEW ROOM
          </button>
        </div>
      </div>

      <div className="new-room-form-main-block">
        <div className="new-room-form-block my-3">
          <div className="row mx-4 p-0 mt-4 mb-2 align-items-center">
            <div className="col-12 col-sm-6 col-md-3 my-1">
              <label className="newRoomInputLabel">
                BRANCH NAME{" "}
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
              </label>
            </div>
            <div className="col-12 col-sm-6 col-md-3 my-2">
              <Controller
                name="branchName"
                control={control}
                defaultValue="JSK WOMENS PG"
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <select
                    {...field}
                    className={`newRoomInputField ${
                      errors.branchName ? "error" : ""
                    }`}
                  >
                    <option value="">Branch Name</option>
                    <option value="JSK WOMENS PG">JSK WOMENS PG</option>
                  </select>
                )}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-3 my-2">
              <label className="newRoomInputLabel">
                NO OF FLOORS{" "}
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
              </label>
            </div>
            <div className="col-12 col-sm-6 col-md-3 my-2">
              <Controller
                name="noOfFloors"
                control={control}
                defaultValue=""
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <input
                    className="newRoomInputField"
                    placeholder="No Of Floors"
                    type="number"
                    min="1"
                    onChange={(e) => {
                      onChange(e); // This updates React Hook Form state
                      setNoOfFloors(e.target.value); // This updates local state
                    }}
                    value={value} // Bind the value to the input
                    onBlur={onBlur}
                    ref={ref}
                  />
                )}
                rules={{ required: "This field is required" }}
              />
            </div>
          </div>

          <div className="row mx-4 p-0 mt-4 mb-2 align-items-center">
            <div className="col-12 col-sm-6 col-md-3 my-1">
              <label className="newRoomInputLabel">
                SELECT FLOOR{" "}
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
              </label>
            </div>
            <div className="col-12 col-sm-6 col-md-3 my-2">
              <Controller
                name="selectFloors"
                control={control}
                defaultValue=""
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <select
                    className="newRoomInputField"
                    onChange={(e) => {
                      const floor = e.target.value;
                      onChange(floor);
                      setSelectedFloor(floor);
                    }}
                  >
                    <option value="">Select Floor</option>
                    {generateFloorOptions().map((floor) => (
                      <option key={floor} value={floor}>
                        {floor}
                      </option>
                    ))}
                    value={value} // Bind the value to the input onBlur={onBlur}
                    ref={ref}
                  </select>
                )}
                rules={{ required: "This field is required" }}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-3 my-2">
              <label className="newRoomInputLabel">NO OF ROOMS</label>
            </div>
            <div className="col-12 col-sm-6 col-md-3 my-2">
              {/* <Controller
                name="noOfRooms"
                control={control}
                defaultValue={0}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <input
                    placeholder="No of Rooms"
                    className="newRoomInputField"
                    type="number"
                    min="0"
                    onChange={(e) => {
                      const count = parseInt(e.target.value, 10) || 0;
                      onChange(count);
                      handleRoomCountChange(selectedFloor, count);
                    }}
                    onBlur={onBlur}
                    ref={ref}
                    value={roomCounts[selectedFloor] || 0}
                  />
                )}
              /> */}
              <Controller
                name="noOfRooms"
                control={control}
                defaultValue={0}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <input
                    placeholder="No of Rooms"
                    className="newRoomInputField"
                    type="number"
                    min="0"
                    onChange={(e) => {
                      const count = parseInt(e.target.value, 10) || 0;
                      onChange(count);
                      handleRoomCountChange(selectedFloor, count); // Ensure selectedFloor is defined in your scope
                    }}
                    onBlur={onBlur}
                    ref={ref}
                    value={value || 0} // Use the value from the Controller
                  />
                )}
              />
            </div>
          </div>

          <div className="row mx-5 p-0 mt-4 mb-2">
            <div className="col-12 newRoomInputBlockContainer">
              <p className="new-room-para pt-2">
                SELECTED FLOOR: {selectedFloor || "None"}
              </p>
              <div className="row">
                {fetchnoofrooms ? (
                  <>
                    {[...Array(fetchnoofrooms)].map((_, index) => (
                      <div
                        key={`${selectedFloor}-room-${index}`}
                        className="col-12 col-sm-6 col-md-3 my-1 d-flex justify-content-center"
                      >
                        <div
                          className="room-container"
                          onClick={() => {
                            handleOpenPopup(
                              `F${selectedFloor.replace("Floor ", "")} - ${
                                index + 1
                              }`
                            );
                          }}
                        >
                          {`F${selectedFloor.replace("Floor ", "")} - ${
                            index + 1
                          }`}
                        </div>
                      </div>
                    ))}
                    {roomCounts[selectedFloor] === 0 && selectedFloor && (
                      <p>No rooms assigned for this floor.</p>
                    )}
                  </>
                ) : (
                  <>
                    {[...Array(roomCounts[selectedFloor] || 0)].map(
                      (_, index) => (
                        <div
                          key={`${selectedFloor}-room-${index}`}
                          className="col-12 col-sm-6 col-md-3 my-1 d-flex justify-content-center"
                        >
                          <div
                            className="room-container"
                            onClick={() => {
                              handleOpenPopup(
                                `F${selectedFloor.replace("Floor ", "")} - ${
                                  index + 1
                                }`
                              );
                            }}
                          >
                            {`F${selectedFloor.replace("Floor ", "")} - ${
                              index + 1
                            }`}
                          </div>
                        </div>
                      )
                    )}
                    {roomCounts[selectedFloor] === 0 && selectedFloor && (
                      <p>No rooms assigned for this floor.</p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <PopupBox
        isOpen={popupState.isOpen}
        onClose={handleClosePopup}
        roomData={popupState.roomData}
        roomNo={popupState.roomNo}
        state={state}
      />
    </>
  );
};

export default NewRoomForm;
