import axios from "axios";
let hostelName = localStorage.getItem("hostelName");

export const getViewRegister = async () => {
  return await axios.get(
    `${process.env.REACT_APP_HOST}/newregister/getAllNewRegister/${hostelName}`
  );
};
export const getViewRoom = async () => {
  return await axios.get(
    `${process.env.REACT_APP_HOST}/rooms/getAllNewRoom/${hostelName}`
  );
};
export const getNoOfFloors = async () => {
  return await axios.get(
    `${process.env.REACT_APP_HOST}/rooms/lastnooffloors/${hostelName}`
  );
};
